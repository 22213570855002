import { useEffect, useState } from 'react';
import { Button, Container, Card, Image, Input, Checkbox, Icon, Label } from 'semantic-ui-react';
import styles from "./KioskHome.module.scss";
import logo from '../img/logo.png';
import carimg from '../img/car.jpg';

const ResultView = ({ setMainView, fields, setFields }) => {
    return (
        <Card className="rego-search-result-card">
            <Card.Content textAlign="center">
                <Card.Header>Vehicle found</Card.Header>
                <Card.Description>
                    <img src={carimg} title="Car" /><br />
                    Not your vehicle? Select cancel.<br /><br />
                    <div className="entry-details">
                        You entered at 10:34 and amount due is $12.50
                    </div>                    
                </Card.Description>                
            </Card.Content>
            <Card.Content extra textAlign="center">
                <Label size="huge" onClick={() => setFields({receiptRequired: !fields.receiptRequired})}><Icon name="print" />Need a receipt? &nbsp;<Checkbox checked={fields.receiptRequired} /></Label>                
                <Button size="huge" icon="cancel" content="Cancel" onClick={() => { setMainView({ view: null }) }} />
                <Button size="huge" icon="shopping cart" content="Next" onClick={() => { setMainView({ view: null }) }} />
            </Card.Content>
        </Card>
    )
}

const SearchView = ({ setMainView }) => {
    return (
        <Card className="rego-search-card">
            <Card.Content textAlign="center">
                <Card.Header>Search for your vehicle</Card.Header><br />
                <Input className="rego-input" size="huge"></Input>
                <Card.Description>
                    Please enter your registration number
                </Card.Description>
            </Card.Content>
            <Card.Content extra textAlign="center">
                <Button style={{ width: "100%" }} size="huge" icon="search" content="Search" onClick={() => { setMainView({ view: ResultView }); }} />
            </Card.Content>
        </Card>
    )
}

const KioskHome = ({ terminalData }) => {
    const [mainView, setMainView] = useState({ view: null });
    const defaultFields = { email: "" , receiptRequired: false };
    const [fields, setFields] = useState(defaultFields);

    const setCurrentView = (view) => {
        if(!view?.view)
            setFields(defaultFields);
        setMainView(view);
    }

    return (
        <>
            <Container className={`${styles.kiosk}`}>
                <Container>
                    <Image title="Logo" className="kiosk-logo" src={logo} centered />
                    <Container className="menu-background">
                        <Card.Group centered>
                            {mainView.view ? <mainView.view setMainView={setCurrentView} terminalData={terminalData} fields={fields} setFields={setFields}
                                cancel={() => { console.log('cancel') }}
                                next={() => { console.log('next') }} /> :
                                <Card>
                                    <Card.Content textAlign="center">
                                        <Card.Header>Pay for parking</Card.Header>
                                        <Card.Description>
                                            Touch here to search for your entry in our system and pay for parking<br />
                                        </Card.Description>
                                    </Card.Content>
                                    <Card.Content extra>
                                        <Button style={{ width: "100%" }} size="huge" icon="car" content="Pay Now" onClick={() => setCurrentView({ view: SearchView })} />
                                    </Card.Content>
                                </Card>}
                        </Card.Group>
                    </Container>
                </Container>
            </Container>
        </>
    )
}

export default KioskHome;